import * as React from "react";
import { IServerError } from "../core/store/global/types";
import { Main, Wrapper } from "./Layout";
import Copy from "./Copy";
import { useTranslation, Trans } from "react-i18next";
import { TFunction } from "i18next";

interface IProps {
  error: IServerError;
}

const HomeOrHelp = () => (
  <p>
    <Trans i18nKey="serverError.homeOrHelp">
      You can return to the <a href="/">home page</a> and try again or{" "}
      <a href="/help">contact us</a> for further help.
    </Trans>
  </p>
);

const renderError = (e: IServerError, t: TFunction) => {
  switch (e.code) {
    case 401:
    case 403:
      return (
        <Copy>
          <h4>
            {t("serverError.permissionDenied.title", "Permission denied")}
          </h4>
          <p>
            {t(
              "serverError.permissionDenied.text",
              "Sorry, but you don't have permission to perform the request action."
            )}
          </p>
          <HomeOrHelp />
        </Copy>
      );
    case 404:
      return (
        <Copy>
          <h4>{t("serverError.notFound.title", "Page not found")}</h4>
          <p>
            {t(
              "serverError.notFound.text",
              "Sorry, but the page you were looking for can't be found."
            )}
          </p>
          <HomeOrHelp />
        </Copy>
      );
    case 502:
    case 503:
    case 504:
      return e.details.match(/being updated/) ? (
        <Copy>
          <h4>{t("serverError.updating.title", "Game Update Notice.")}</h4>
          <p>
            {t(
              "serverError.updating.text",
              "The RSL Fantasy game will be in update mode and not accessible from Monday to Wednesday. Once the game is back online, you will have to reset your password by selecting 'Forgot Password' when you log in."
            )}
            {t(
              "serverError.updating.SignOff",
              " Thank you for your understanding!"
            )}
          </p>
        </Copy>
      ) : (
        <Copy>
          <h4>{t("serverError.unavailable.title", "Unavailable")}</h4>
          <p>
            {t(
              "serverError.unavailable.text1",
              "Sorry, but we are unable to load the requested page at this time."
            )}
          </p>
          <p>
            {t(
              "serverError.unavailable.text2",
              "Please try again in a few minutes."
            )}
          </p>
        </Copy>
      );
    default:
      return (
        <Copy>
          <h2>{t("serverError.default.title", "Error")}</h2>
          <p>
            {t(
              "serverError.default.text",
              "Sorry, but there has been an unexpected error processing your request."
            )}
          </p>
          <HomeOrHelp />
        </Copy>
      );
  }
};

const ServerError: React.FC<IProps> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>{renderError(error, t)}</Main>
    </Wrapper>
  );
};

export default ServerError;
